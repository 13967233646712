import React from 'react';
import { Container } from 'react-bootstrap';

/**
 * Assets
 */

import './styles/_index.scss';

const Intro = ({children}) => {
    
    return (
        <div className="intro">
            <Container>
                {children}
            </Container>            
        </div>
    )
}

export default Intro
