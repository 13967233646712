import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { Container } from 'react-bootstrap';
import 'intersection-observer';
import { useInView } from 'react-intersection-observer';
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw'
import { useStaticQuery, graphql } from "gatsby"


/**
 * Assets
 */
import './styles/_index.scss';

const Valuation = (props) => {
  const data = useStaticQuery(graphql`
  query  {  
    strapiGlobalConfig {
      Footer_Box_Title
      Footer_Box_Content
    }
  }
`)

    var footer_data = data.strapiGlobalConfig;
    const [ref, inView] = useInView({
        threshold: 0,
        triggerOnce: true,
    });



    return (
        <div className={classNames('valuation btm-valuation', {'is-active': inView})} ref={ref} data-aos="fade-up" data-aos-duration="1000">
            <Container>
                <h5>{footer_data.Footer_Box_Title}</h5>
                <ReactMarkdown rehypePlugins={[rehypeRaw]} children={footer_data.Footer_Box_Content}/>
            </Container>
        </div>
    )
}

export default Valuation
